<template>
  <div>
    <div style="width: 100%; padding-top: 16px; margin-top: 0px;">
      <table
        class="team_table"
      >
        <thead>
          <tr>
            <th>
              {{ $t('OrderID') }}
            </th>
            <th>
              {{ $t('OrderType') }}
            </th>
            <th>
              {{ $t('CustomerName') }}
            </th>
            <th>
              {{ $t('BillingCountry') }}
            </th>
            <th>
              {{ $t('ShippingCountry') }}
            </th>
            <th>
              {{ $t('Created') }}
            </th>
            <!-- <th>
              {{ $t('AdjustedDelivery') }}
            </th> -->
            <!-- <th>
              {{ $t("Actions") }}
            </th> -->
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="order in getNewNominativeOrders"
            :key="order.id"
            class="hover-row"
            style="cursor: pointer"
            :style="classNameCh === order.orderNumber ? 'background-color: rgba(255, 39, 79, 0.1)' : ''"
            @click="ordersMaterials(order)"
          >
            <td>{{ order.orderNumber }}</td>
            <td>{{ $t(order.orderType) }}</td>
            <td>{{ order.customerName }}</td>
            <td>{{ order.billingAddressCountry }}</td>
            <td>{{ order.shippingAddressCountry }}</td>
            <td>{{ convertUTCDateToLoacalDate(order.created) }}</td>
            <!-- <td>{{ order.adjustedPickupDate }}</td> -->
          </tr>
        </tbody>
      </table>
      <div>
        <b-pagination
          v-if="getNewNominativeOrdersTotalItems > 15"
          v-model="pageNumber"
          :total-rows="getNewNominativeOrdersTotalItems"
          :per-page="pageSize"
          first-number
          last-number
          align="fill"
          size="md"
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
        >
          <template #prev-text>
            <span class="previousOrNext"><b-icon-arrow-left />{{ $t('Previous') }}</span>
          </template>
          <template #next-text>
            <span class="previousOrNext">{{ $t('Next') }}<b-icon-arrow-right /></span>
          </template>
        </b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'

export default {
  components: {},
  data() {
    return {
      pageNumber: 1,
      pageSize: 15,
      clientId: null,
      orderNumber: null,
      classNameCh: '',
    }
  },
  computed: {
    ...mapGetters(['getNewNominativeOrders', 'getNewNominativeOrdersTotalItems']),
  },
  watch: {
    pageNumber(value) {
      this.createdMaterialOrders({
        pageNumber: value,
        pageSize: this.pageSize,
        orderNumber: this.orderNumber,
        clientId: this.clientId,
      })
    },
  },
  mounted() {
    this.createdMaterialOrders({
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      orderNumber: this.orderNumber,
      clientId: this.clientId,
    })
  },
  methods: {
    ...mapActions(['createdMaterialOrders', 'loadCreatedOrdersByOrderNumber']),
    convertUTCDateToLoacalDate(date) {
      const local = moment.utc(date).local().format('DD-MMM-YYYY h:mm A')
      return local
    },
    ordersMaterials(value) {
      this.classNameCh = value.orderNumber
      this.loadCreatedOrdersByOrderNumber(
        {
          id: value.orderNumber,
          pageNumber: 1,
          pageSize: 15,
        },
      )
    },
  },
}
</script>

<style>
.team_table td {
  padding: 12px 9px !important;
}
.hover-row:hover {
    background-color: rgba(255, 39, 79, 0.1);
}
</style>
