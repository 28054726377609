<template>
  <div
    style="margin-top: 10px;"
  >
    <section class="main">
      <div
        class="flex"
      >
        <OrdersInNominative />
        <ArticleListingNominativ />
      </div>
    </section>

  </div></template>
<script>
import OrdersInNominative from '../components/materialsNominvative/OrdersInNominative.vue'
import ArticleListingNominativ from '../components/materialsNominvative/ArticleListingNominativ.vue'

export default {
  components: {
    OrdersInNominative,
    ArticleListingNominativ,
  },
}
</script>

<style>
@media screen and (min-width: 1440px) and (max-width: 1520px) {
  .main{
    padding: 80px 15px 15px 90px;
  }
}

@media screen and (max-width: 1440px){
  .main{
    padding: 75px 15px 15px 80px;
  }
}

@media screen and (max-width: 1340px){
  .main{
    padding: 75px 15px 15px 90px;
  }
}
 @media screen and (max-width: 1200px) {
  .main {
    padding: 70px 10px 10px 20px;
  }
}
</style>
